<template>
  <base-template :show-head="showHead">
    <slot name="header"></slot>
    <ul class="list-box">
      <li v-for="(item,index) in contentArry" :key="index">
        <div v-if="item.ticket_title" class="title">{{ item.ticket_title }}</div>
        <span v-for="(items,indexs) in item.ticket_content" :key="indexs">{{ items }}</span>
      </li>
    </ul>
  </base-template>
</template>

<script>
import { getCouponExplain } from '@/api/axios/index'
export default {
  props: {
    showHead: {
      type: Boolean,
      default: true
    },
    ruleType: {
      type: [String, Number],
      default: '3'
    }
  },
  data() {
    return {
      title: '',
      contentArry: []
    }
  },
  created() {
    this.getCouponExplain()
  },
  methods: {
    async getCouponExplain() {
      const { data } = await getCouponExplain({ ticket_type: this.ruleType }) || {}
      this.contentArry = data || []
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.is-content){
  background: #fff;
}
.title{
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #000;
  padding-bottom: 40px;
}
ul{
  background: #F7FAFF;
  border-radius: 16px;
  padding: 30px;
  margin-top: 40px;
}
ul li{
  margin-bottom: 20px;
  font-size: 26px;
  font-family: Poppins-Regular, Poppins;
  font-weight: 400;
  color: #858597;
  line-height: 28px;
}
</style>
